
import DpFormMixin from 'src/core/components/DpForm/DpFormMixin.js'
import DpForm from 'src/core/components/DpForm/DpForm2.vue'
import NotificacaoMixin from 'src/core/mixin/NotificacaoMixin'
import vTopFilters from 'src/core/filters/vTopFilters'
import { clone } from 'quasar'
import VersaoModel from 'src/model/veiculo/VersaoModel'

import Upload from 'src/components/Upload.vue'
import axios from 'axios'

export default {
  name: 'VersaoForm',
  mixins: [DpFormMixin, NotificacaoMixin, vTopFilters],
  components: { Upload, DpForm },
  data () {
    return {
      modelPadrao: new VersaoModel(),
      codigos_icarros: [],
      codigos_webmotors: [],
      codigos_usadosbr: [],
      codigos_olx: [],
      codigos_mobiauto: [],
      salvando: false
    }
  },
  meta () {
    return {
      title: 'Versoes',
      meta: [
        { 'name': 'robots', 'content': 'noindex' }
      ]
    }
  },
  methods: {
    setarImagem (e) {
      this.modelPadrao.form.foto = e.arquivo
      this.modelPadrao.form.foto_url_completa = e.arquivo.arquivo_url_completa
    },
    salvarForm (e) {
      this.salvando = true
      let form = clone(this.modelPadrao.form)
      this.notificacao('aviso', 'Salvando...', 3000)
      this.modelPadrao.salvar(form).then((res) => {
        console.log(res)
        this.salvando = false
        this.notificacao('salvo', 'Salvo com sucesso', 3000)
        this.$router.push({ name: 'versoes.grid' })
      })
    },
    setarDados () {
      let form = clone(this.modelPadrao.form)
      this.modelPadrao.form = Object.assign({}, form)
    },
    inicio () {
    }
  },
  watch: {
    '$route': {
      handler: function (agora, depois) {
        this.inicio()
      },
      deep: false,
      immediate: false
    }
  },
  mounted () {
    if (this.$route.params.id && this.$route.params.id > 0) {
      this.modelPadrao.getId({ id: this.$route.params.id }).then((response) => {
        console.log('response', response)
        axios
          .get(
            process.env.API['automotor'] +
              '/icarros/versoes/' +
              this.modelPadrao.form.modelo.marca_id + '/' +
              this.modelPadrao.form.modelo_id +
              '?v2=1'
          )
          .then(res => {
            this.codigos_icarros = res.data.dados ?? []
          })
        axios
          .get(
            process.env.API['automotor'] +
              '/webmotors/versoes/' +
              this.modelPadrao.form.modelo_id +
              '?v2=1'
          )
          .then(res => {
            this.codigos_webmotors = res.data.dados ?? []
          })
        axios
          .get(
            process.env.API['automotor'] +
              '/usadosbr/versoes/' +
              this.modelPadrao.form.modelo_id +
              '?v2=1'
          )
          .then(res => {
            this.codigos_usadosbr = res.data.dados ?? []
          })
        axios
          .get(
            process.env.API['automotor'] +
              '/olx/versoes/' +
              this.modelPadrao.form.modelo_id +
              '?v2=1'
          )
          .then(res => {
            this.codigos_olx = res.data.dados ?? []
          })
        axios
          .get(
            process.env.API['automotor'] +
              '/mobiauto/versoes/' +
              this.modelPadrao.form.modelo_id +
              '?v2=1'
          )
          .then(res => {
            this.codigos_mobiauto = res.data.dados ?? []
          })
      })
    }
    this.inicio()
  }
}
